<template>
  <div
    class="field field--thin field--standalone"
    :class="{
      'field--error': errors.length && errors[0].type === 'error',
    }"
  >
    <span class="field__label" v-if="_text">
      {{ _text }}
    </span>
    <slot></slot>
  </div>
</template>

<script>
import error from '../../img/baseline-error-24px.svg';

import compact from 'lodash/compact';

export default {
  name: 'ControlHead',
  props: {
    label: {},
    name: {},
    type: {},
    validation: {},
    value: {},
  },
  data() {
    return {
      validated: false,
      errors: [],
    };
  },
  computed: {
    _text() {
      return this.errors.length ? this.errors[0].message : this.label;
    },
  },
  methods: {
    change() {
      this.validated = false;
      this.validate().catch(() => {}); // Ignore errors
    },
    addError(error) {
      this.errors.push(this.errorFormatter(error));
    },
    errorFormatter(err) {
      if (!err.message) err = { message: err };

      if (!err.type) err.type = 'error';
      if (!err.iconTitle) err.iconTitle = 'Error!';

      return err;
    },
    // TODO: REFACTOR to async/await
    validate() {
      return new Promise((resolve, reject) => {
        const done = (result) => {
          this.validated = true;

          return resolve(result);
        };

        if (!this.validation) return done(true);

        Promise.all(
          (Array.isArray(this.validation) ? this.validation : [this.validation]).map((v) => {
            let res = v(this.value, this);

            // Async validation
            if (res && res.then) return res;

            // Sync validation
            return Promise.resolve(res);
          })
        )
          .then((results) => {
            this.errors = compact(results).map(this.errorFormatter);

            done(!this.errors.length);
          })
          .catch(() => {
            // Skip client side validation if unknown errors happen
            resolve(true);
          });
      });
    },
    validates() {
      if (!this.validated) return this.validate();

      return Promise.resolve(!this.errors.length);
    },
  },
  watch: {
    value() {
      this.change();
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/field.scss
</style>
