export function _open() {
  this.open = true;
  this.$emit('open', true);
}

export function setDropdownDimensions($anchor, { margin } = { margin: '6vh' }) {
  const anchorBottomPosition = $anchor.$refs.input.getBoundingClientRect().bottom;
  let maxHeight = window.innerHeight - anchorBottomPosition;

  if (typeof margin === 'number') {
    maxHeight -= margin;
    maxHeight += 'px';
  } else {
    maxHeight = `calc(${maxHeight}px - ${margin})`;
  }

  this.dropdownStyles.maxHeight = maxHeight;
}

export function _close() {
  this.open = false;
  this.$emit('open', false);
  document.removeEventListener('focusin', this._close);
}

export function _focusin() {
  this._open();

  // Reset any event listeners to avoid triggering the same event here and as parent
  document.removeEventListener('focusin', this._close);
  setTimeout(() => {
    // Catch any focus events outside of the field
    document.addEventListener('focusin', this._close);
  });
}

export function _documentClick(event) {
  if (!this.open) return;

  if (!this.$refs.field.$refs.container.contains(event.target)) this._close();
}

export function toggle() {
  if (this.open) {
    if (document.activeElement) document.activeElement.blur();
    this._close();
  } else {
    this.$refs.field.setFocus();
  }
}

export function openCloseData() {
  return {
    open: false,
    dropdownStyles: {},
  };
}

export function mounted() {
  document.addEventListener('click', this._documentClick);
}

export function beforeDestroy() {
  document.removeEventListener('click', this._documentClick);
  document.removeEventListener('focusin', this._close);
}

export const openCloseInternalMethods = { _close, _open, _focusin, _documentClick };
