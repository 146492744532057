<template>
  <BaseField
    ref="field"
    v-bind="$attrs"
    v-on="removeInputListener($listeners)"
    @change="_change"
    @iconClick="toggle"
    @tagClick="_open"
    @focusin="_focusin"
    :value="tags"
    @input="addRemoveFootwearTypes"
    @fieldValue="(val) => (inputValue = val)"
    :icon="icon"
    :errors="errors"
    @keydown="_keydown"
  >
    <template v-slot:dropdown v-if="open">
      <div class="field-dropdown" :style="dropdownStyles">
        <h4 class="field-dropdown__heading">Product information</h4>
        <!--<p class="field-dropdown__sub-heading">The more detailed information, the better supplier match</p>-->
        <div class="field-dropdown__content">
          <ControlHead label="Product type" :value="filters.footwearTypes">
            <checkbox-custom
              v-for="(footwearType, index) in filteredFootwearTypes"
              name="product"
              :label="footwearType.title"
              :key="index"
              @change="dropdownChange()"
              v-model="footwearType.checked"
            />
          </ControlHead>
        </div>
        <div class="field-dropdown__actions field-dropdown__actions--sticky">
          <fs-button-main
            class="field-dropdown__action-button"
            styling="outlined"
            @click.prevent="resetTags({ close: true })"
            >Cancel</fs-button-main
          >
          <fs-button-main class="field-dropdown__action-button" @click.prevent="_close()">Apply</fs-button-main>
        </div>
      </div>
    </template>
  </BaseField>
</template>

<script>
import sneaker from 'apps/public/img/sneaker.svg';

import {
  openCloseInternalMethods,
  setDropdownDimensions,
  toggle,
  mounted,
  beforeDestroy,
  openCloseData,
} from 'apps/public/lib/open-close-field-dropdown';
import {
  addError,
  errorFormatter,
  resetValidation,
  validate,
  validates,
  validationData,
  validationProps,
} from 'apps/public/lib/validation';

import BaseField from 'apps/public/pages/components/base-field.vue';
import ControlHead from 'apps/public/pages/components/control-head.vue';
import Field from 'apps/public/pages/components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import FsButtonIcon from 'apps/public/pages/components/fs-button-icon.vue';
import CheckboxCustom from '@components/form-elements/checkbox-custom.vue';

export default Object.assign(
  {},
  {},
  {
    name: 'ProductField',
    components: { BaseField, Field, FsButtonMain, FsButtonIcon, ControlHead, CheckboxCustom },
    inheritAttrs: false,
    props: {
      value: {},
      ...validationProps,
    },
    data() {
      const footwearTypes = this.$store.categories.all
        .find((c) => c.slug === 'footwear-types')
        .articles.map((a) => {
          return {
            title: a.title,
            checked: false,
          };
        });

      return {
        filters: {
          footwearTypes,
        },
        inputValue: '',
        tags: [],
        ...openCloseData(this),
        ...validationData(this),
      };
    },
    computed: {
      filteredFootwearTypes() {
        const inputValue = this.inputValue.trim().toLowerCase();

        if (!inputValue) return this.filters.footwearTypes;

        return this.filters.footwearTypes.filter((f) => f.title.toLowerCase().includes(inputValue));
      },
      icon() {
        return {
          iconSVG: sneaker,
          iconTitle: this.label,
          iconSmall: true,
          iconFixed: true,
          iconToggled: this.open,
        };
      },
    },
    methods: {
      async _change(val) {
        this.resetValidation();
      },
      removeInputListener($listeners) {
        if (!$listeners.input) return $listeners;

        // Create a copy without input, since we want to handle it manually and
        // not automatically trigger parent component listeners on base field
        // component's input event.
        const $listenersCopy = { ...$listeners };
        delete $listenersCopy.input;

        return $listenersCopy;
      },
      addRemoveFootwearTypes(newTags) {
        const newTagsLowerCase = newTags.map((t) => t.toLowerCase());
        const currentTagsLowerCase = this.tags.map((t) => t.toLowerCase());
        const footwearTypes = this.filters.footwearTypes.map((f) => f.title.toLowerCase());

        // Add new tags and check corresponding checkboxes
        const newTagsIndices = newTagsLowerCase
          .filter((t) => footwearTypes.includes(t)) // But not tags that doesn't exist as footwearType
          .filter((t) => !currentTagsLowerCase.includes(t)) // Only new tags
          .map((t) => this.filters.footwearTypes.findIndex((f) => f.title.toLowerCase() === t)); // Get proper indices

        newTagsIndices.forEach((index) => (this.filters.footwearTypes[index].checked = true));

        this.tags = this.tags.concat(newTagsIndices.map((index) => this.filters.footwearTypes[index].title)); // Add properly cased tags

        // Remove no longer existing tags - but only footwear tags (and remove checks from corresponding checkboxes)
        for (let index = this.tags.length - 1; index >= 0; index--) {
          const tag = currentTagsLowerCase[index];

          if (!newTagsLowerCase.includes(tag) && footwearTypes.includes(tag)) {
            this.filters.footwearTypes.find((f) => f.title.toLowerCase() === tag).checked = false;
            this.tags.splice(index, 1);
          }
        }

        // Trigger parent change if not open
        if (!this.open) this.$emit('input', this.valueFromTags());
      },
      tagsFromValue() {
        let footwearTypes = this.value.footwearTypes;

        if (!Array.isArray(footwearTypes)) footwearTypes = [footwearTypes];

        return footwearTypes;
      },
      valueFromTags() {
        return {
          footwearTypes: this.tags,
        };
      },
      resetTags({ close } = { close: false }) {
        this.addRemoveFootwearTypes(this.tagsFromValue());

        if (close) this._close();
      },
      dropdownChange() {
        this.addRemoveFootwearTypes(this.filters.footwearTypes.filter((f) => f.checked).map((f) => f.title));
      },
      _keydown($event) {
        const { keyCode } = $event;

        // Esc => Cancel
        if (keyCode === 27) {
          const wasOpen = this.open;

          this.resetTags({ close: true });
          if (!wasOpen) this.$refs.field.reset();
        }
      },
      ...openCloseInternalMethods,
      setDropdownDimensions,
      toggle,
      addError,
      errorFormatter,
      resetValidation,
      validate,
      validates,
    },
    watch: {
      open(open) {
        this.setDropdownDimensions(this.$refs.field);

        if (!open) {
          this.$emit('input', this.valueFromTags());
        }
      },
    },
    created() {
      this.resetTags();
    },
    mounted,
    beforeDestroy,
  }
);
</script>

<style lang="scss">
@use 'sass:math';

// See ~ common/styles/field.scss
</style>
