<template>
  <BaseField
    ref="field"
    v-bind="$attrs"
    v-on="removeInputListener($listeners)"
    @change="_change"
    @tagClick="_open"
    @focusin="_focusin"
    :value="localValue.total"
    @input="_input"
    :errors="errors"
    @keydown="_keydown"
  >
    <template v-slot:dropdown v-if="open">
      <div class="field-dropdown" :style="dropdownStyles">
        <h4 class="field-dropdown__heading">Quantity</h4>
        <!--<p class="field-dropdown__sub-heading">The more detailed information, the better supplier match</p>-->
        <div class="field-dropdown__content">
          <Slider name="quantity" label="Total quantity" :data="totalSliderData" v-model="localValue.total" />
        </div>
        <div class="field-dropdown__actions field-dropdown__actions--sticky">
          <fs-button-main
            class="field-dropdown__action-button"
            styling="outlined"
            @click.prevent="resetTags({ close: true })"
            >Cancel</fs-button-main
          >
          <fs-button-main class="field-dropdown__action-button" @click.prevent="_close()">Apply</fs-button-main>
        </div>
      </div>
    </template>
  </BaseField>
</template>

<script>
import {
  openCloseInternalMethods,
  setDropdownDimensions,
  toggle,
  mounted,
  beforeDestroy,
  openCloseData,
} from 'apps/public/lib/open-close-field-dropdown';
import {
  addError,
  errorFormatter,
  resetValidation,
  validate,
  validates,
  validationData,
  validationProps,
} from 'apps/public/lib/validation';

import BaseField from 'apps/public/pages/components/base-field.vue';
import ControlHead from 'apps/public/pages/components/control-head.vue';
import Field from 'apps/public/pages/components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import FsButtonIcon from 'apps/public/pages/components/fs-button-icon.vue';
import Slider from 'apps/public/pages/components/slider.vue';

export default Object.assign(
  {},
  {},
  {
    name: 'QuantityField',
    components: { BaseField, Field, FsButtonMain, FsButtonIcon, ControlHead, Slider },
    inheritAttrs: false,
    props: {
      value: {},
      ...validationProps,
    },
    data() {
      return {
        localValue: {
          total: null,
        },
        totalSliderData: [1, 20, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000],
        ...openCloseData(this),
        ...validationData(this),
      };
    },
    computed: {},
    methods: {
      async _change(val) {
        this.resetValidation();
      },
      _input(val) {
        this.localValue.total = val; // To ensure we trigger an update of the field value - e.g. filtering out alphabetic characters right away
        this.localValue.total = val.replace(/[^0-9]/gi, '');
      },
      removeInputListener($listeners) {
        if (!$listeners.input) return $listeners;

        // Create a copy without input, since we want to handle it manually and
        // not automatically trigger parent component listeners on base field
        // component's input event.
        const $listenersCopy = { ...$listeners };
        delete $listenersCopy.input;

        return $listenersCopy;
      },
      reset({ close } = { close: false }) {
        this.localValue = { ...this.value }; // To avoid referencing the original value and triggering changes unexpectedly

        if (close) this._close();
      },
      _keydown($event) {
        const { keyCode } = $event;

        // Esc => Cancel
        if (keyCode === 27) {
          const wasOpen = this.open;

          this.reset({ close: true });
          if (!wasOpen) this.$refs.field.blur();
        }
      },
      ...openCloseInternalMethods,
      setDropdownDimensions,
      toggle,
      addError,
      errorFormatter,
      resetValidation,
      validate,
      validates,
    },
    watch: {
      open(open) {
        this.setDropdownDimensions(this.$refs.field);

        if (!open) {
          this.$emit('input', this.localValue);
        }
      },
    },
    created() {
      this.reset();
    },
    mounted,
    beforeDestroy,
  }
);
</script>

<style lang="scss">
@use 'sass:math';

// See ~ common/styles/field.scss
</style>
