var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-field",
    _vm._g(
      _vm._b(
        {
          ref: "field",
          attrs: {
            value: _vm.tags,
            icon: _vm.icon,
            errors: _vm.errors,
            autocomplete: _vm.autocomplete,
          },
          on: {
            change: _vm._change,
            iconClick: _vm.toggle,
            tagClick: _vm._open,
            focusin: _vm._focusin,
            input: _vm.addRemove,
            fieldValue: function (val) {
              return (_vm.inputValue = val)
            },
            keydown: _vm._keydown,
          },
          scopedSlots: _vm._u(
            [
              _vm.open
                ? {
                    key: "dropdown",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "field-dropdown",
                            style: _vm.dropdownStyles,
                          },
                          [
                            _c(
                              "h4",
                              { staticClass: "field-dropdown__heading" },
                              [_vm._v("Production region")]
                            ),
                            _vm._v(" "),
                            _vm.filteredRegions.length
                              ? _c(
                                  "div",
                                  { staticClass: "field-dropdown__content" },
                                  [
                                    _c(
                                      "control-head",
                                      {
                                        attrs: {
                                          label: "Regions",
                                          value: _vm.filters.regions,
                                        },
                                      },
                                      _vm._l(
                                        _vm.filteredRegions,
                                        function (region, index) {
                                          return _c("checkbox-custom", {
                                            key: index,
                                            attrs: {
                                              name: "regions",
                                              label: region.title,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.regionChange(region)
                                              },
                                            },
                                            model: {
                                              value: region.checked,
                                              callback: function ($$v) {
                                                _vm.$set(region, "checked", $$v)
                                              },
                                              expression: "region.checked",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.filteredCountries.length
                              ? _c(
                                  "div",
                                  { staticClass: "field-dropdown__content" },
                                  [
                                    _c(
                                      "control-head",
                                      {
                                        attrs: {
                                          label: "Countries",
                                          value: _vm.filters.countries,
                                        },
                                      },
                                      _vm._l(
                                        _vm.filteredCountries,
                                        function (country, index) {
                                          return _c("checkbox-custom", {
                                            key: index,
                                            attrs: {
                                              name: "countries",
                                              label: country.title,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.countryChange()
                                              },
                                            },
                                            model: {
                                              value: country.checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  country,
                                                  "checked",
                                                  $$v
                                                )
                                              },
                                              expression: "country.checked",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "field-dropdown__actions field-dropdown__actions--sticky",
                              },
                              [
                                _c(
                                  "fs-button-main",
                                  {
                                    staticClass:
                                      "field-dropdown__action-button",
                                    attrs: { styling: "outlined" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.resetTags({ close: true })
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "fs-button-main",
                                  {
                                    staticClass:
                                      "field-dropdown__action-button",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm._close()
                                      },
                                    },
                                  },
                                  [_vm._v("Apply")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        "base-field",
        _vm.$attrs,
        false
      ),
      _vm.removeInputListener(_vm.$listeners)
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }