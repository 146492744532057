<template>
  <div
    class="field"
    :class="{
      'field--has-focus': focus,
      'field--error': errors.length && errors[0].type === 'error',
      'field--thin': !caption && !tall,
    }"
  >
    <label class="field__label" :for="_id" v-if="!labels">
      {{ label }}
      <span class="slider__small-label">{{ value }}</span>
    </label>
    <div class="slider__container">
      <span
        class="slider__value"
        ref="value"
        :style="{
          'min-width': labelMinWidth,
        }"
        v-if="!labels"
        >{{ value }}</span
      >
      <span class="slider__label" v-if="labels">{{ labels[0] }}</span>
      <vue-slider
        class="slider"
        ref="slider"
        :dot-size="18"
        :value="value"
        :piecewise="false"
        :use-keyboard="true"
        :data="data"
        :disabled="!!disabled"
        :height="4"
        width="100%"
        :tooltip="false"
        :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
        :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
        :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
        @callback="update"
      >
      </vue-slider>
      <span class="slider__label" v-if="labels">{{ labels[1] }}</span>
    </div>
    <span v-if="_caption" class="field__caption">
      {{ captionText }}
    </span>
  </div>
</template>

<script>
// TODO: Review validation whenever we start using it
// TODO: Review the use if vue-slider-component - or perhaps we should update to 3.0?
// Previous data for moq :data="[1, 20, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000]"
// Known issues:
// - Doesn't drop focus when mousepress is released
// - This component doesn't handle focus/blur properly?
// - You can't tab to this component/focus - it'll just skip it
// - The code of vue-slider-component is messy and not tree-shakable
//
// TODO: Review tooltip and if we can find a nice design for it (that also works with longer numbers)
import compact from 'lodash/compact';
import { v4 as uuidv4 } from 'uuid';
import VueSlider from 'vue-slider-component/src/vue2-slider.vue';

export default {
  name: 'Slider',
  components: { VueSlider },
  props: {
    caption: {},
    data: {},
    disabled: {},
    id: {},
    label: {},
    labels: {},
    labelMinWidth: {
      type: String,
      default: '5.375em', // Encompasses <any 4 numbers>-<any 4 numbers>
    },
    name: {},
    tall: {},
    validation: {},
    value: {},
  },
  data() {
    return {
      focus: false,
      validated: false,
      errors: [],
    };
  },
  computed: {
    _id() {
      return this.id || (this.name || '') + '-' + uuidv4();
    },
    _caption() {
      return (this.caption || this.errors.length) && !!this.captionText;
    },
    captionText() {
      return this.errors.length ? this.errors[0].message : this.caption;
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value);
    },
    async change() {
      this.validated = false;
      this.validate().catch(() => {}); // Ignore errors

      this.$emit('change', this.value);
    },
    _focus() {
      this.focus = true;
      this.$emit('focus');
    },
    _blur() {
      this.focus = false;
      this.$emit('blur');
    },
    addError(error) {
      this.errors.push(this.errorFormatter(error));
    },
    errorFormatter(err) {
      if (!err.message) err = { message: err };

      if (!err.type) err.type = 'error';

      return err;
    },
    // TODO: REFACTOR to async/await
    validate() {
      return new Promise((resolve, reject) => {
        const done = (result) => {
          this.validated = true;

          return resolve(result);
        };

        if (!this.validation) return done(true);

        Promise.all(
          (Array.isArray(this.validation) ? this.validation : [this.validation]).map((v) => {
            let res = v(this.value, this);

            // Async validation
            if (res && res.then) return res;

            // Sync validation
            return Promise.resolve(res);
          })
        )
          .then((results) => {
            this.errors = compact(results).map(this.errorFormatter);

            done(!this.errors.length);
          })
          .catch(() => {
            // Skip client side validation if unknown errors happen
            resolve(true);
          });
      });
    },
    validates() {
      if (!this.validated) return this.validate();

      return Promise.resolve(!this.errors.length);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/field.scss for base/frame styling
@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.slider {
  width: auto !important;
  flex-grow: 1;

  &__small-label {
    display: none;

    @include mobile-only {
      display: inline-block;
    }
  }

  &__container {
    display: flex;
    height: 2.58em;
    align-items: center;

    @include tablet-min {
      padding-right: 0.5em;
    }
  }

  &__value {
    display: none;

    @include tablet-min {
      @include body;
      display: inline-block;
      box-sizing: content-box;
      padding: 0 0.5em 0 0.5em;
      text-align: center;
    }
  }

  &__label {
    @include overline;
    margin-top: -0.0625rem;

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }

  // Styling of vue-slider (over-riding);
  &.vue-slider-component {
    cursor: pointer;

    .vue-slider {
      border-radius: $br-light;
    }

    .vue-slider-process {
      border-radius: $br-light;
    }
    /*.vue-slider-piecewise-dot {
      border-radius: $br-light;
    }*/

    .vue-slider-dot.vue-slider-dot-focus .vue-slider-dot-handle {
      // REVIEW: If this is really the best way of having focus
      // See selection-box.scss for other types of focus, and
      // perhaps compare to material design/ripple?
      box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32); // This is equal to non-focus styling
      transform: scale(1.1);
    }
    /*.vue-slider-tooltip {
      @include caption--small;
      color: $c-light;
      border-radius: $br-light;
      border-color: $c-blue;
      background-color: $c-blue;
      padding: 0 0.25rem;
    }

    .vue-slider-tooltip-wrap.vue-slider-tooltip-top .vue-slider-tooltip::before,
    .vue-slider-tooltip-top .vue-merged-tooltip .vue-slider-tooltip::before {
      bottom: -8px;
      border-width: 4px;
    }*/
  }
}
</style>
