<template>
  <div class="suppliers__container">
    <div class="top-section">
      <div class="request__container">
        <form class="request">
          <h2 class="request__heading">What are you looking to produce?</h2>
          <div class="request__fields">
            <Product
              class="request__product-field"
              name="product"
              ref="product"
              label="Product"
              v-model="filters.product"
            />

            <Region
              class="request__region-field"
              name="region"
              ref="region"
              label="Production region"
              v-model="filters.location"
            />

            <div class="request__other-fields">
              <Quantity
                class="request__quantity-field"
                name="quantity"
                ref="quantity"
                label="Total quantity"
                v-model="filters.quantity"
              />
              <!--<Product class="request__sizes-field" />-->
              <!--<div class="request__slider">(slider)</div>-->
            </div>
          </div>
          <div class="request__buttons">
            <fs-button-main class="request__button" size="large" :long="true"
              >Get started sourcing, it's free</fs-button-main
            >
            <span class="request__promo-text">Reach all relevant supplier with one request!</span>
          </div>
        </form>
      </div>
      <suppliers-map
        class="top-section__map"
        :data="mapData"
        :countries="countries"
        :loading="$store.general.loading"
      />
    </div>
    <div class="search-results">
      <div class="suppliers__usps">
        <div class="usp" :title="`${suppliers.count} suppliers`">
          <span class="usp__icon" v-html="svg.factory"></span>
          <span class="usp__text"
            ><strong>{{ suppliers.count }}</strong> suppliers</span
          >
        </div>
        <div class="usp" :title="`${suppliers.countries} countries`">
          <span class="usp__icon" v-html="svg.publicSVG"></span>
          <span class="usp__text"
            ><strong>{{ suppliers.countries }}</strong> countries</span
          >
        </div>
        <div class="usp" title="Start sourcing to get in contact with all relevant ones directly - for free">
          <span class="usp__icon" v-html="svg.money"></span>
          <span class="usp__text"
            ><strong>Start sourcing</strong> to get in contact with all relevant ones directly - for free</span
          >
        </div>
      </div>
      <div class="suppliers">
        <router-link
          tag="div"
          class="supplier"
          v-for="supplier in suppliers.list"
          :to="{ name: 'supplier', params: { name: supplier.slug } }"
          :key="supplier.id"
          exact
        >
          <div class="supplier-image">
            <img class="supplier-image__image" :src="imagePath(`images/${supplier.coverPhoto}`, 960, 250)" />
          </div>
          <h3 class="supplier__heading">{{ supplier.title }}</h3>
          <div class="supplier__quick-facts">
            <div class="quick-fact">
              <span
                class="quick-fact__icon"
                v-html="$store.suppliers.isManufacturer(supplier.type) ? svg.factory : svg.business"
              ></span>
              <span class="quick-fact__text">{{ $store.suppliers.types[supplier.type] }}</span>
            </div>
            <div class="quick-fact">
              <span class="quick-fact__icon" v-html="svg.publicSVG"></span>
              <span class="quick-fact__text"><!--Buenos Aires,-->{{ supplier.country }}</span>
            </div>
            <div class="quick-fact" v-if="supplier.dailyProductionFrom && supplier.dailyProductionTo">
              <span class="quick-fact__icon" v-html="svg.clockCog"></span>
              <span class="quick-fact__text"
                >{{ supplier.dailyProductionFrom }}-{{ supplier.dailyProductionTo }} pairs/day</span
              >
            </div>
            <div class="quick-fact" v-if="supplier.employees">
              <span class="quick-fact__icon" v-html="svg.people"></span>
              <span class="quick-fact__text">{{ supplier.employees }} workers</span>
            </div>
            <div class="quick-fact">
              <span class="quick-fact__icon" v-html="svg.gender"></span>
              <span class="quick-fact__text">{{ mergeGender(supplier) }}</span>
            </div>
            <div class="quick-fact" v-if="supplier.moq">
              <span class="quick-fact__icon" v-html="svg.assignment"></span>
              <span class="quick-fact__text">{{ supplier.moq }}+ pairs/order</span>
            </div>
          </div>
          <!-- br-elements are to force line breaks in IE11 where it doesn't work otherwise -->
          <div class="supplier__capabilities">
            <div
              class="capability"
              :class="{ 'capability--inactive': !$store.suppliers.offersPreDevelopmentSupport(supplier.capabilities) }"
            >
              <span class="capability__icon" v-html="svg.lightbulb"></span>
              <span class="capability__label"
                >Pre&#8209;development<br />
                support</span
              >
            </div>
            <div
              class="capability"
              :class="{ 'capability--inactive': !$store.suppliers.offersDevelopmentSupport(supplier.capabilities) }"
            >
              <span class="capability__icon" v-html="svg.design"></span>
              <span class="capability__label"
                >Development<br />
                support</span
              >
            </div>
            <div
              class="capability"
              :class="{ 'capability--inactive': !$store.suppliers.offersProduction(supplier.capabilities) }"
            >
              <span class="capability__icon" v-html="svg.factory"></span>
              <span class="capability__label">Production</span>
            </div>
            <div
              class="capability"
              :class="{ 'capability--inactive': !$store.suppliers.offersShipping(supplier.capabilities) }"
            >
              <span class="capability__icon" v-html="svg.shipping"></span>
              <span class="capability__label"
                >Shipping&nbsp;&amp;<br />
                logistics</span
              >
            </div>
          </div>
          <div class="quotation-field">
            <span class="quotation-field__label">Product capabilities</span>
            <div class="quotation-field__tags">
              <span class="quotation-field-tag" v-for="id in supplier.footwearTypes.split(',').slice(0, 4)" :key="id">
                <span class="quotation-field-tag__icon" v-html="getProductTypeIcon(id)"></span>
                <span class="quotation-field-tag__text">{{ getTitle(id) }}</span>
              </span>
              <span class="quotation-field-tag" v-if="supplier.footwearTypes.split(',').length > 4">
                <span class="quotation-field-tag__text">...</span>
              </span>
            </div>
          </div>
          <div class="quotation-field">
            <span class="quotation-field__label">Process capabilities</span>
            <div class="quotation-field__tags">
              <span class="quotation-field-tag" v-for="id in supplier.processes.split(',').slice(0, 4)" :key="id">
                <span class="quotation-field-tag__text">{{ getTitle(id) }}</span>
              </span>
              <span class="quotation-field-tag" v-if="supplier.processes.split(',').length > 4">
                <span class="quotation-field-tag__text">...</span>
              </span>
            </div>
          </div>
          <div class="supplier__buttons">
            <fs-button-main
              class="supplier__button"
              styling="text"
              color="dark"
              :to="{ name: 'supplier', params: { name: supplier.slug } }"
              exact
              >Full profile</fs-button-main
            >
          </div>
        </router-link>
      </div>
      <div class="search-results__loader" :class="{ 'search-results__loader--loading': $store.general.loading }"></div>
    </div>
  </div>
</template>

<script>
import { imagePath } from '@lib/imgproxy';
import isEqualWith from 'lodash/isEqualWith';
import sortBy from 'lodash/sortBy';

import FsButtonMain from '@components/buttons/fs-button-main.vue';
import Product from './fields/product.vue';
import Quantity from './fields/quantity.vue';
import Region from './fields/region.vue';
import suppliersMap from 'apps/public/pages/components/map/suppliers-map.vue';

// General icons
import assignment from 'apps/public/img/assignment-24px.svg';
import business from 'apps/public/img/business-24px.svg';
import clockCog from 'apps/public/img/clock-cog-outline.svg';
import design from 'apps/public/img/design.svg';
import factory from 'apps/public/img/factory.svg';
import gender from 'apps/public/img/gender.svg';
import lightbulb from 'apps/public/img/lightbulb-outline.svg';
import money from 'apps/public/img/attach_money-24px.svg';
import shipping from 'apps/public/img/local_shipping_outlined-24px.svg';
import people from 'apps/public/img/people-24px.svg';
import publicSVG from 'apps/public/img/public-24px.svg';

// Shoe (product capabilities) icons
import balletFlats from 'apps/public/img/ballet-flats.svg';
import boots from 'apps/public/img/boots.svg';
import espadrilles from 'apps/public/img/espadrilles.svg';
import flipFlops from 'apps/public/img/flip-flops.svg';
import heeledShoes from 'apps/public/img/heeled-shoes.svg';
import moccasins from 'apps/public/img/moccasins.svg';
import sandals from 'apps/public/img/sandals.svg';
import slippers from 'apps/public/img/slippers.svg';
import shoes from 'apps/public/img/shoes.svg';
import sneaker from 'apps/public/img/sneaker.svg';

export default {
  name: 'footwearSuppliers',
  components: { FsButtonMain, Product, Quantity, Region, suppliersMap },
  load({ store, route }) {
    let url = `/api/suppliers?category=0`;
    let urlQuery = route.fullPath.split('?')[1];
    //let subscriptionUrl = `category=0`;
    if (urlQuery) {
      url += `&${urlQuery}`;
      //subscriptionUrl += `&${urlQuery}`;
    }

    store.head.title = `FindSourcing – Footwear Suppliers`;

    return Promise.all([
      store.suppliers.loadList(`${url}&data=list`),
      store.suppliers.loadCoordinates(`${url}&data=coordinates`),
      store.suppliers.loadFilters(),
      //,
      //store.subscriptions.checkStatus(subscriptionUrl) // TODO: REVIEW if we want to add back subscription or not
    ]);
  },
  data() {
    return {
      filters: this.getFiltersFromQuery(),
      svg: {
        assignment,
        business,
        clockCog,
        design,
        factory,
        gender,
        lightbulb,
        money,
        people,
        publicSVG,
        shipping,
        balletFlats,
        boots,
        espadrilles,
        flipFlops,
        heeledShoes,
        moccasins,
        sandals,
        slippers,
        shoes,
        sneaker,
      },
    };
  },
  computed: {
    requestUrl() {
      let url = `/api/suppliers?category=0`;
      let urlQuery = this.$route.fullPath.split('?')[1];
      if (urlQuery) url += `&${urlQuery}`;
      return url;
    },
    suppliers() {
      return this.$store.suppliers.list[`${this.requestUrl}&data=list`] || [];
    },
    countries() {
      let countries = this.$route.query.location;
      if (!countries) return [];

      if (typeof countries == 'string') {
        countries = [countries];
      }

      return countries;
    },
    mapData() {
      let data = this.$store.suppliers.coordinates[`${this.requestUrl}&data=coordinates`] || [];
      let coordinates = [];
      data.forEach((item) => {
        if (item.coordinates) {
          coordinates.push({
            id: item.id,
            latitude: item.coordinates.split(', ')[0],
            longitude: item.coordinates.split(', ')[1],
          });
        }
      });
      return coordinates;
    },
  },
  methods: {
    imagePath,
    // Filters
    getFiltersFromQuery() {
      const filters = {
        product: {
          footwearTypes: [],
          productTypes: [],
        },
        location: [],
        quantity: {
          total: null,
        },
        sizeRangeFrom: null,
        sizeRangeTo: null,
      };

      if (this.$route.query.footwearTypes) filters.product.footwearTypes = this.$route.query.footwearTypes;
      if (this.$route.query.location) filters.location = this.$route.query.location;
      if (this.$route.query.moq) filters.quantity.total = parseInt(this.$route.query.moq);

      return filters;
    },
    getQueryFromFilters() {
      let query = {};

      if (this.filters.product.footwearTypes.length) query.footwearTypes = this.filters.product.footwearTypes;
      if (this.filters.location.length) query.location = this.filters.location;
      if (this.filters.quantity.total) query.moq = '' + this.filters.quantity.total;

      return query;
    },
    // Render helpers
    mergeGender(supplier) {
      let genders = [];

      function capitalizeFirstChar(str) {
        return str[0].toUpperCase() + str.slice(1);
      }

      if (supplier.ladies) genders.push('women');
      if (supplier.men) genders.push('men');
      if (supplier.kids) genders.push('kids');
      if (supplier.babies) genders.push('babies');

      if (genders.length === 1) return capitalizeFirstChar(genders[0]);

      let result = genders.pop();
      result = `${genders.pop()} & ${result}`;

      while (genders.length) {
        result = `${genders.pop()}, ${result}`;
      }

      return capitalizeFirstChar(result);
    },
    getProductTypeIcon(id) {
      const title = this.getTitle(id);

      if (title === 'Ballet Flats') return this.svg.balletFlats;
      if (title === 'Boots') return this.svg.boots;
      if (title === 'Espadrilles') return this.svg.espadrilles;
      if (title === 'Flip Flops') return this.svg.flipFlops;
      if (title === 'Heeled Shoes') return this.svg.heeledShoes;
      if (title === 'Moccasin') return this.svg.moccasins;
      if (title === 'Sandals') return this.svg.sandals;
      if (title === 'Slippers') return this.svg.slippers;
      if (title === 'Shoes') return this.svg.shoes;

      return this.svg.sneaker;
    },
    getTitle(id) {
      return this.$store.knowledgebank.titles.find((a) => a.id == id).title;
    },
  },
  watch: {
    filters: {
      handler: function () {
        // Don't trigger navigation on equal filters - even if sorted
        // differently, since it doesn't affect the results
        if (
          isEqualWith(this.$route.query, this.getQueryFromFilters(), (a, b) => {
            if (!Array.isArray(a) || !Array.isArray(b)) {
              // If there's only one item, this.$route.query (when parsed from url) is not
              // treated as an array of items, but as a single item. Then, try to wrap the
              // value as an array and check if that works better. If it doesn't, let the
              // default handler take care of matching instead.
              const arrA = !Array.isArray(a) && a ? [a] : a;
              const arrB = !Array.isArray(b) && b ? [b] : b;

              if (isEqualWith(sortBy(arrA), sortBy(arrB))) return true;

              return undefined;
            }

            return isEqualWith(sortBy(a), sortBy(b));
          })
        )
          return;

        this.$router.push(
          { name: this.$route.name, query: this.getQueryFromFilters() },
          this.$store.general.loadingLock()
        );
      },
      deep: true,
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

.footwearSuppliers {
  .suppliers {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0.375rem;

    @include mobile-only {
      justify-content: center;
    }

    &__container {
    }

    &__usps {
      display: flex;
      overflow: hidden;
      margin-top: 2rem;
      margin-left: (0.75rem + 1.25rem);
      margin-right: (0.75rem + 1.25rem);

      @include mobile-to-tablet {
        margin-left: (0.75rem + 0.5rem);
        margin-right: (0.75rem + 0.5rem);
      }
    }
  }

  .search-results {
    position: relative;

    // Based on loader (see frame/index.vue);
    &__loader {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
      background: rgba($c-light, 0.95);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease, visibility 0.2s ease;
      &--loading {
        visibility: visible;
        opacity: 1;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - #{math.div(1rem, 1.6)});
        top: calc(50% - #{math.div(1rem, 1.6)});
        height: math.div(2rem, 1.6);
        width: math.div(2rem, 1.6);
        border: 2px solid $c-blue;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        animation: spinAround 500ms infinite linear;
      }
    }
  }

  .top-section {
    display: flex;
    background-color: $c-bg-gray;
    box-shadow: $bs-old-medium;

    @include mobile-only {
      justify-content: center;
    }

    &__map {
      flex-basis: 45%;
      flex-grow: 0;
      flex-shrink: 1;

      @include desktop-max {
        flex-basis: 40%;
      }

      @include mobile-to-tablet {
        flex-basis: 50%;
      }

      @include mobile-only {
        display: none;
      }
    }
  }

  .suppliers-map-container__loader {
    background-color: rgba($c-bg-gray, 0.87);
  }

  .request {
    // One column layout;
    @include tablet-portrait-only {
      width: 24rem;
    }

    &__container {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 0;
      margin-top: calc(1rem + 3vw);
      margin-bottom: calc(1.25rem + 2.75vw);
      padding-left: calc(2rem + 2.25vw);
      padding-right: calc(2rem + 2.25vw);

      @include mobile-only {
        flex-shrink: 1;
        // Same as usps for more aligned layout (even though it breaks agains base padding);
        padding-left: (0.75rem + 0.5rem);
        padding-right: (0.75rem + 0.5rem);
      }
    }

    &__heading {
      @include heading--large;
      margin-top: 0.5rem;
    }

    &__fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1.25rem;
      grid-template-rows: auto auto;
      grid-template-areas:
        'product region'
        'other other';
      margin-top: 1.25rem;
    }

    &__product-field {
      grid-area: product;
    }

    &__region-field {
      grid-area: region;
    }

    &__other-fields {
      grid-area: other;
      display: flex;
    }

    &__quantity-field {
      flex-basis: 8.375rem;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 1.25rem;

      // TODO: Review if we really want to do it like this - since it goes against BEM
      .field-dropdown {
        @include tablet-min {
          width: 20rem;
        }
      }
    }

    &__sizes-field {
      flex-basis: 5.5rem;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 1.25rem;
    }

    &__slider {
      flex-shrink: 1;
      flex-grow: 1;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: calc(2.788rem + 2px); // The same height as a button - facilitates hiding promo-text when it wraps;
      overflow: hidden;
      margin-top: 0.75rem;
    }

    &__button {
    }

    &__promo-text {
      @include body;
      font-style: italic;
      font-weight: 700;
      margin-left: 1.5rem;
    }
  }

  .usp {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
      overflow: hidden;

      @include mobile-xs-only {
        display: none;
      }
    }

    &__icon {
      position: relative;
      left: -0.125rem;
      fill: $c-dark;
      width: 1.25rem;
      height: 1.25rem;
      flex-shrink: 0;
      margin-right: 0.5rem;
    }

    &__text {
      @include body;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // Suppliers section
  .supplier {
    position: relative;
    max-width: 37rem;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 24.5rem;
    padding: 1.25rem;
    margin: 0.75rem;
    // regular bottom padding + button height (excluding border) + buttons top margin + buttons bottom margin + button border;
    padding-bottom: calc((1.25rem + 2.6095rem + 0.875rem - 0.25rem) + 2px);
    background-color: $c-bg-light;
    box-shadow: $bs-medium; // Taken from pages/home;
    cursor: pointer;

    @include mobile-only {
      flex-basis: 24rem;
    }

    &:hover {
      box-shadow: $bs-high-medium;
    }

    &-image {
      position: relative;
      height: 0;
      padding-top: 32.25%;
      overflow: hidden;

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__heading {
      @include heading--small;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 1rem;
    }

    &__quick-facts {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 0.375rem;
      margin-right: -1.25rem; // To accomodate each items margin-right and that the right-most should fit "without" margin;
    }

    &__capabilities {
      display: flex;
      justify-content: space-around;
      margin-top: 2rem;
      padding-bottom: 1rem;
    }

    &__buttons {
      position: absolute;
      bottom: (1.25rem - 0.25rem);
      left: 1.25rem;
      right: 1.25rem;
      display: flex;
      justify-content: flex-end;
    }

    &__button {
    }
  }

  .quick-fact {
    position: relative;
    padding-left: 1.75rem;
    margin-right: 1.25rem;
    margin-bottom: 0.375rem;
    flex-grow: 0;
    flex-shrink: 0;

    &__icon {
      // Based on quotations/request/quotation.vue -> quick-fact-icon;
      position: absolute;
      top: 0.125rem;
      left: 0;
      fill: $c-dark;
      width: 1.125rem;
      height: 1.125rem;
    }

    &__text {
      @include body--small;
    }
  }

  .capability {
    flex-basis: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    &--inactive {
      opacity: 0.37;
    }

    &__icon {
      fill: $c-dark;
      width: 1.25rem;
      height: 1.25rem;
    }

    &__label {
      @include caption--small;
      line-height: 1.428;
      text-align: center;
      margin-top: 0.125rem;
    }
  }

  /*.quotation-field {
      &__label {
        @include overline;
      }

      &__tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        //margin-top: 0.375rem;
        margin-right: -0.375rem; // To accomodate each items margin-right and that the right-most should fit "without" margin;
      }

      &-tag {
        position: relative;
        background-color: rgba($c-dark, 0.07);
        border-radius: $br-light;
        padding-left: (1.75rem + 0.75rem);
        padding-right: 0.875rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-right: 0.375rem;
        margin-bottom: 0.375rem;
        flex-grow: 0;
        flex-shrink: 0;

        &__icon {
          // Based on quotations/request/quotation.vue -> quick-fact-icon;
          position: absolute;
          top: (0.0625rem + 0.25rem);
          left: 0.75rem;
          fill: $c-dark;
          width: 1.25rem;
          height: 1.25rem;
        }

        &__text {
          @include body--small;
        }
      }
    }*/

  .quotation-field {
    margin-top: 0.75rem;

    &__label {
      @include overline;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 0.25rem;
      margin-right: -0.375rem; // To accomodate each items margin-right and that the right-most should fit "without" margin;
    }

    &-tag {
      position: relative;
      background-color: rgba($c-dark, 0.07);
      border-radius: $br-light;
      margin-right: 0.375rem;
      margin-bottom: 0.375rem;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 1.875rem;
      padding-left: 0.75rem;
      padding-right: 0.875rem;

      &:hover {
        background-color: rgba($c-dark, 0.13);
      }

      &__icon {
        position: relative;
        left: -0.125rem;
        fill: $c-dark;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
      }

      &__text {
        @include body--small;
      }
    }
  }
}
</style>
