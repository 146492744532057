var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "field",
      class: {
        "field--has-focus": _vm.focus,
        "field--error": _vm.errors.length && _vm.errors[0].type === "error",
        "field--thin": !_vm.caption && !_vm.tall,
      },
    },
    [
      !_vm.labels
        ? _c(
            "label",
            { staticClass: "field__label", attrs: { for: _vm._id } },
            [
              _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
              _c("span", { staticClass: "slider__small-label" }, [
                _vm._v(_vm._s(_vm.value)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "slider__container" },
        [
          !_vm.labels
            ? _c(
                "span",
                {
                  ref: "value",
                  staticClass: "slider__value",
                  style: {
                    "min-width": _vm.labelMinWidth,
                  },
                },
                [_vm._v(_vm._s(_vm.value))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.labels
            ? _c("span", { staticClass: "slider__label" }, [
                _vm._v(_vm._s(_vm.labels[0])),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("vue-slider", {
            ref: "slider",
            staticClass: "slider",
            attrs: {
              "dot-size": 18,
              value: _vm.value,
              piecewise: false,
              "use-keyboard": true,
              data: _vm.data,
              disabled: !!_vm.disabled,
              height: 4,
              width: "100%",
              tooltip: false,
              bgStyle: { backgroundColor: "rgba(55, 73, 94, 0.13)" },
              sliderStyle: {
                backgroundColor: "#fff",
                border: "2px solid #37495e",
              },
              processStyle: { backgroundColor: "rgba(55, 73, 94, 0.8)" },
            },
            on: { callback: _vm.update },
          }),
          _vm._v(" "),
          _vm.labels
            ? _c("span", { staticClass: "slider__label" }, [
                _vm._v(_vm._s(_vm.labels[1])),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._caption
        ? _c("span", { staticClass: "field__caption" }, [
            _vm._v("\n    " + _vm._s(_vm.captionText) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }