var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseField",
    _vm._g(
      _vm._b(
        {
          ref: "field",
          attrs: { value: _vm.localValue.total, errors: _vm.errors },
          on: {
            change: _vm._change,
            tagClick: _vm._open,
            focusin: _vm._focusin,
            input: _vm._input,
            keydown: _vm._keydown,
          },
          scopedSlots: _vm._u(
            [
              _vm.open
                ? {
                    key: "dropdown",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "field-dropdown",
                            style: _vm.dropdownStyles,
                          },
                          [
                            _c(
                              "h4",
                              { staticClass: "field-dropdown__heading" },
                              [_vm._v("Quantity")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "field-dropdown__content" },
                              [
                                _c("Slider", {
                                  attrs: {
                                    name: "quantity",
                                    label: "Total quantity",
                                    data: _vm.totalSliderData,
                                  },
                                  model: {
                                    value: _vm.localValue.total,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.localValue, "total", $$v)
                                    },
                                    expression: "localValue.total",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "field-dropdown__actions field-dropdown__actions--sticky",
                              },
                              [
                                _c(
                                  "fs-button-main",
                                  {
                                    staticClass:
                                      "field-dropdown__action-button",
                                    attrs: { styling: "outlined" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.resetTags({ close: true })
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "fs-button-main",
                                  {
                                    staticClass:
                                      "field-dropdown__action-button",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm._close()
                                      },
                                    },
                                  },
                                  [_vm._v("Apply")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        "BaseField",
        _vm.$attrs,
        false
      ),
      _vm.removeInputListener(_vm.$listeners)
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }