var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "suppliers__container" }, [
    _c(
      "div",
      { staticClass: "top-section" },
      [
        _c("div", { staticClass: "request__container" }, [
          _c("form", { staticClass: "request" }, [
            _c("h2", { staticClass: "request__heading" }, [
              _vm._v("What are you looking to produce?"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "request__fields" },
              [
                _c("Product", {
                  ref: "product",
                  staticClass: "request__product-field",
                  attrs: { name: "product", label: "Product" },
                  model: {
                    value: _vm.filters.product,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "product", $$v)
                    },
                    expression: "filters.product",
                  },
                }),
                _vm._v(" "),
                _c("Region", {
                  ref: "region",
                  staticClass: "request__region-field",
                  attrs: { name: "region", label: "Production region" },
                  model: {
                    value: _vm.filters.location,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "location", $$v)
                    },
                    expression: "filters.location",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "request__other-fields" },
                  [
                    _c("Quantity", {
                      ref: "quantity",
                      staticClass: "request__quantity-field",
                      attrs: { name: "quantity", label: "Total quantity" },
                      model: {
                        value: _vm.filters.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "quantity", $$v)
                        },
                        expression: "filters.quantity",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "request__buttons" },
              [
                _c(
                  "fs-button-main",
                  {
                    staticClass: "request__button",
                    attrs: { size: "large", long: true },
                  },
                  [_vm._v("Get started sourcing, it's free")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "request__promo-text" }, [
                  _vm._v("Reach all relevant supplier with one request!"),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("suppliers-map", {
          staticClass: "top-section__map",
          attrs: {
            data: _vm.mapData,
            countries: _vm.countries,
            loading: _vm.$store.general.loading,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "search-results" }, [
      _c("div", { staticClass: "suppliers__usps" }, [
        _c(
          "div",
          {
            staticClass: "usp",
            attrs: { title: _vm.suppliers.count + " suppliers" },
          },
          [
            _c("span", {
              staticClass: "usp__icon",
              domProps: { innerHTML: _vm._s(_vm.svg.factory) },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "usp__text" }, [
              _c("strong", [_vm._v(_vm._s(_vm.suppliers.count))]),
              _vm._v(" suppliers"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "usp",
            attrs: { title: _vm.suppliers.countries + " countries" },
          },
          [
            _c("span", {
              staticClass: "usp__icon",
              domProps: { innerHTML: _vm._s(_vm.svg.publicSVG) },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "usp__text" }, [
              _c("strong", [_vm._v(_vm._s(_vm.suppliers.countries))]),
              _vm._v(" countries"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "usp",
            attrs: {
              title:
                "Start sourcing to get in contact with all relevant ones directly - for free",
            },
          },
          [
            _c("span", {
              staticClass: "usp__icon",
              domProps: { innerHTML: _vm._s(_vm.svg.money) },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "suppliers" },
        _vm._l(_vm.suppliers.list, function (supplier) {
          return _c(
            "router-link",
            {
              key: supplier.id,
              staticClass: "supplier",
              attrs: {
                tag: "div",
                to: { name: "supplier", params: { name: supplier.slug } },
                exact: "",
              },
            },
            [
              _c("div", { staticClass: "supplier-image" }, [
                _c("img", {
                  staticClass: "supplier-image__image",
                  attrs: {
                    src: _vm.imagePath(
                      "images/" + supplier.coverPhoto,
                      960,
                      250
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "supplier__heading" }, [
                _vm._v(_vm._s(supplier.title)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "supplier__quick-facts" }, [
                _c("div", { staticClass: "quick-fact" }, [
                  _c("span", {
                    staticClass: "quick-fact__icon",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$store.suppliers.isManufacturer(supplier.type)
                          ? _vm.svg.factory
                          : _vm.svg.business
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "quick-fact__text" }, [
                    _vm._v(_vm._s(_vm.$store.suppliers.types[supplier.type])),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "quick-fact" }, [
                  _c("span", {
                    staticClass: "quick-fact__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.publicSVG) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "quick-fact__text" }, [
                    _vm._v(_vm._s(supplier.country)),
                  ]),
                ]),
                _vm._v(" "),
                supplier.dailyProductionFrom && supplier.dailyProductionTo
                  ? _c("div", { staticClass: "quick-fact" }, [
                      _c("span", {
                        staticClass: "quick-fact__icon",
                        domProps: { innerHTML: _vm._s(_vm.svg.clockCog) },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "quick-fact__text" }, [
                        _vm._v(
                          _vm._s(supplier.dailyProductionFrom) +
                            "-" +
                            _vm._s(supplier.dailyProductionTo) +
                            " pairs/day"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                supplier.employees
                  ? _c("div", { staticClass: "quick-fact" }, [
                      _c("span", {
                        staticClass: "quick-fact__icon",
                        domProps: { innerHTML: _vm._s(_vm.svg.people) },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "quick-fact__text" }, [
                        _vm._v(_vm._s(supplier.employees) + " workers"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "quick-fact" }, [
                  _c("span", {
                    staticClass: "quick-fact__icon",
                    domProps: { innerHTML: _vm._s(_vm.svg.gender) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "quick-fact__text" }, [
                    _vm._v(_vm._s(_vm.mergeGender(supplier))),
                  ]),
                ]),
                _vm._v(" "),
                supplier.moq
                  ? _c("div", { staticClass: "quick-fact" }, [
                      _c("span", {
                        staticClass: "quick-fact__icon",
                        domProps: { innerHTML: _vm._s(_vm.svg.assignment) },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "quick-fact__text" }, [
                        _vm._v(_vm._s(supplier.moq) + "+ pairs/order"),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "supplier__capabilities" }, [
                _c(
                  "div",
                  {
                    staticClass: "capability",
                    class: {
                      "capability--inactive":
                        !_vm.$store.suppliers.offersPreDevelopmentSupport(
                          supplier.capabilities
                        ),
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "capability__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.lightbulb) },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "capability__label" }, [
                      _vm._v("Pre‑development"),
                      _c("br"),
                      _vm._v("\n              support"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "capability",
                    class: {
                      "capability--inactive":
                        !_vm.$store.suppliers.offersDevelopmentSupport(
                          supplier.capabilities
                        ),
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "capability__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.design) },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "capability__label" }, [
                      _vm._v("Development"),
                      _c("br"),
                      _vm._v("\n              support"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "capability",
                    class: {
                      "capability--inactive":
                        !_vm.$store.suppliers.offersProduction(
                          supplier.capabilities
                        ),
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "capability__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.factory) },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "capability__label" }, [
                      _vm._v("Production"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "capability",
                    class: {
                      "capability--inactive":
                        !_vm.$store.suppliers.offersShipping(
                          supplier.capabilities
                        ),
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "capability__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.shipping) },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "capability__label" }, [
                      _vm._v("Shipping &"),
                      _c("br"),
                      _vm._v("\n              logistics"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quotation-field" }, [
                _c("span", { staticClass: "quotation-field__label" }, [
                  _vm._v("Product capabilities"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "quotation-field__tags" },
                  [
                    _vm._l(
                      supplier.footwearTypes.split(",").slice(0, 4),
                      function (id) {
                        return _c(
                          "span",
                          { key: id, staticClass: "quotation-field-tag" },
                          [
                            _c("span", {
                              staticClass: "quotation-field-tag__icon",
                              domProps: {
                                innerHTML: _vm._s(_vm.getProductTypeIcon(id)),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "quotation-field-tag__text" },
                              [_vm._v(_vm._s(_vm.getTitle(id)))]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    supplier.footwearTypes.split(",").length > 4
                      ? _c("span", { staticClass: "quotation-field-tag" }, [
                          _c(
                            "span",
                            { staticClass: "quotation-field-tag__text" },
                            [_vm._v("...")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "quotation-field" }, [
                _c("span", { staticClass: "quotation-field__label" }, [
                  _vm._v("Process capabilities"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "quotation-field__tags" },
                  [
                    _vm._l(
                      supplier.processes.split(",").slice(0, 4),
                      function (id) {
                        return _c(
                          "span",
                          { key: id, staticClass: "quotation-field-tag" },
                          [
                            _c(
                              "span",
                              { staticClass: "quotation-field-tag__text" },
                              [_vm._v(_vm._s(_vm.getTitle(id)))]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    supplier.processes.split(",").length > 4
                      ? _c("span", { staticClass: "quotation-field-tag" }, [
                          _c(
                            "span",
                            { staticClass: "quotation-field-tag__text" },
                            [_vm._v("...")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "supplier__buttons" },
                [
                  _c(
                    "fs-button-main",
                    {
                      staticClass: "supplier__button",
                      attrs: {
                        styling: "text",
                        color: "dark",
                        to: {
                          name: "supplier",
                          params: { name: supplier.slug },
                        },
                        exact: "",
                      },
                    },
                    [_vm._v("Full profile")]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "search-results__loader",
        class: {
          "search-results__loader--loading": _vm.$store.general.loading,
        },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "usp__text" }, [
      _c("strong", [_vm._v("Start sourcing")]),
      _vm._v(" to get in contact with all relevant ones directly - for free"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }