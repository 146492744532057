<template>
  <base-field
    ref="field"
    v-bind="$attrs"
    v-on="removeInputListener($listeners)"
    @change="_change"
    @iconClick="toggle"
    @tagClick="_open"
    @focusin="_focusin"
    :value="tags"
    @input="addRemove"
    @fieldValue="(val) => (inputValue = val)"
    :icon="icon"
    :errors="errors"
    @keydown="_keydown"
    :autocomplete="autocomplete"
  >
    <template v-if="open" v-slot:dropdown>
      <div class="field-dropdown" :style="dropdownStyles">
        <h4 class="field-dropdown__heading">Production region</h4>
        <!--<p class="field-dropdown__sub-heading">The more detailed information, the better supplier match</p>-->
        <div v-if="filteredRegions.length" class="field-dropdown__content">
          <control-head label="Regions" :value="filters.regions">
            <checkbox-custom
              v-for="(region, index) in filteredRegions"
              name="regions"
              :label="region.title"
              :key="index"
              @change="regionChange(region)"
              v-model="region.checked"
            />
          </control-head>
        </div>
        <div v-if="filteredCountries.length" class="field-dropdown__content">
          <control-head label="Countries" :value="filters.countries">
            <checkbox-custom
              v-for="(country, index) in filteredCountries"
              name="countries"
              :label="country.title"
              :key="index"
              @change="countryChange()"
              v-model="country.checked"
            />
          </control-head>
        </div>
        <div class="field-dropdown__actions field-dropdown__actions--sticky">
          <fs-button-main
            class="field-dropdown__action-button"
            styling="outlined"
            @click.prevent="resetTags({ close: true })"
            >Cancel</fs-button-main
          >
          <fs-button-main class="field-dropdown__action-button" @click.prevent="_close()">Apply</fs-button-main>
        </div>
      </div>
    </template>
  </base-field>
</template>

<script>
import publicIcon from 'apps/public/img/public-24px.svg';
import { v4 as uuidv4 } from 'uuid';

import {
  openCloseInternalMethods,
  setDropdownDimensions,
  toggle,
  mounted,
  beforeDestroy,
  openCloseData,
} from 'apps/public/lib/open-close-field-dropdown';
import {
  addError,
  errorFormatter,
  resetValidation,
  validate,
  validates,
  validationData,
  validationProps,
} from 'apps/public/lib/validation';

import BaseField from '@components/base-field.vue';
import ControlHead from '@components/control-head.vue';
import Field from '@components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import FsButtonIcon from '@components/fs-button-icon.vue';
import CheckboxCustom from '@components/form-elements/checkbox-custom.vue';

function filteredFiltersFactory(filterName) {
  return function () {
    const inputValue = this.inputValue.trim().toLowerCase();

    if (!inputValue) return this.filters[filterName];

    return this.filters[filterName].filter((f) => f.title.toLowerCase().includes(inputValue));
  };
}

export default Object.assign(
  {},
  {},
  {
    name: 'RegionField',
    components: { BaseField, Field, FsButtonMain, FsButtonIcon, ControlHead, CheckboxCustom },
    inheritAttrs: false,
    props: {
      value: {},
      ...validationProps,
    },
    data() {
      const countries = this.$store.suppliers.filters.footwearSuppliersFilters.countries.map((c) => {
        return {
          title: c,
          checked: false,
        };
      });

      const regions = this.$store.suppliers.filters.footwearSuppliersFilters.regions.map((r) => {
        return {
          title: r.name,
          code: r.code,
          checked: {
            checked: false,
            indeterminate: false,
          },
        };
      });

      return {
        filters: {
          countries,
          regions,
        },
        inputValue: '',
        tags: [],
        autocomplete: uuidv4(),
        ...openCloseData(this),
        ...validationData(this),
      };
    },
    computed: {
      checkedCountries() {
        return this.filters.countries.filter((c) => c.checked).map((c) => c.title);
      },
      filteredCountries: filteredFiltersFactory('countries'),
      filteredRegions: filteredFiltersFactory('regions'),
      icon() {
        return {
          iconSVG: publicIcon,
          iconTitle: this.label,
          iconSmall: true,
          iconFixed: true,
          iconToggled: this.open,
        };
      },
    },
    methods: {
      async _change(val) {
        this.resetValidation();
      },
      removeInputListener($listeners) {
        if (!$listeners.input) return $listeners;

        // Create a copy without input, since we want to handle it manually and
        // not automatically trigger parent component listeners on base field
        // component's input event.
        const $listenersCopy = { ...$listeners };
        delete $listenersCopy.input;

        return $listenersCopy;
      },
      addRemove(newTags) {
        const newTagsLowerCase = newTags.map((t) => t.toLowerCase());
        const currentTagsLowerCase = this.tags.map((t) => t.toLowerCase());
        const countries = this.filters.countries.map((c) => c.title.toLowerCase());

        // Add new tags and check corresponding checkboxes
        const newTagsIndices = newTagsLowerCase
          .filter((t) => countries.includes(t)) // But not tags that doesn't exist as country
          .filter((t) => !currentTagsLowerCase.includes(t)) // Only new tags
          .map((t) => this.filters.countries.findIndex((c) => c.title.toLowerCase() === t)); // Get proper indices

        newTagsIndices.forEach((index) => (this.filters.countries[index].checked = true));

        this.tags = this.tags.concat(newTagsIndices.map((index) => this.filters.countries[index].title)); // Add properly cased tags

        // Remove no longer existing tags - but only country tags (and remove checks from corresponding checkboxes)
        for (let index = this.tags.length - 1; index >= 0; index--) {
          const tag = currentTagsLowerCase[index];

          if (!newTagsLowerCase.includes(tag) && countries.includes(tag)) {
            this.filters.countries.find((c) => c.title.toLowerCase() === tag).checked = false;
            this.tags.splice(index, 1);
          }
        }

        // Merge country tags into regions for each region where all countries exist as tags
        this.$store.suppliers.filters.footwearSuppliersFilters.regions.forEach((region) => {
          if (region.countries.length === region.countries.filter((c) => this.tags.includes(c.name)).length) {
            // All countries for region exists as tags...
            // Remove all countries and get position where to insert region
            const countries = region.countries.map((c) => c.name);
            let insertPosition = this.tags.length;

            for (let index = this.tags.length - 1; index >= 0; index--) {
              if (countries.includes(this.tags[index])) {
                this.tags.splice(index, 1);
                insertPosition = index;
              }
            }

            // Add region - if it doesn't already exist in the list
            if (!this.tags.includes(region.name)) this.tags.splice(insertPosition, 0, region.name);
          } else {
            // All countries doesn't exist as tags for region - remove region as tag (if it exists as tag)
            const regionPosition = this.tags.findIndex((t) => t === region.name);

            if (regionPosition > -1) this.tags.splice(regionPosition, 1);
          }
        });

        this.updateRegionCheckboxes();

        // Trigger parent change if not open
        if (!this.open) this.$emit('input', this.valueFromTags());
      },
      tagsFromValue() {
        let countries = this.value;

        if (!Array.isArray(countries)) countries = [countries];

        return countries;
      },
      valueFromTags() {
        const regions = this.$store.suppliers.filters.footwearSuppliersFilters.regions.map((region) =>
          region.name.toLowerCase()
        );

        // Replace regions with countries
        return [...this.tags]
          .map((t) =>
            regions.includes(t.toLowerCase())
              ? this.$store.suppliers.filters.footwearSuppliersFilters.regions
                  .find((region) => region.name.toLowerCase() === t.toLowerCase())
                  .countries.map((c) => c.name)
              : t
          )
          .flat();
      },
      resetTags({ close } = { close: false }) {
        this.addRemove(this.tagsFromValue());

        if (close) this._close();
      },
      updateTagsFromCheckboxes() {
        this.addRemove(this.checkedCountries);
      },
      countryChange() {
        this.updateTagsFromCheckboxes();
      },
      updateRegionCheckboxes() {
        this.$store.suppliers.filters.footwearSuppliersFilters.regions.forEach((region) => {
          const checkedCountriesForRegion = region.countries.filter((c) => this.checkedCountries.includes(c.name));
          const filterRegion = this.filters.regions.find((r) => r.code === region.code);

          if (!checkedCountriesForRegion.length) {
            // Uncheck regions where all countries are unchecked
            filterRegion.checked.checked = false;
            filterRegion.checked.indeterminate = false;
          } else if (checkedCountriesForRegion.length === region.countries.length) {
            // Check regions where all countries are checked
            filterRegion.checked.checked = true;
            filterRegion.checked.indeterminate = false;
          } else {
            filterRegion.checked.indeterminate = true;
          }
        });
      },
      regionChange(region) {
        // Check/uncheck all region's countries to match region's check
        const checked = region.checked.checked;
        const countries = this.$store.suppliers.filters.footwearSuppliersFilters.regions
          .find((r) => r.code === region.code)
          .countries.map((c) => c.name);

        this.filters.countries
          .filter((c) => countries.includes(c.title) && c.checked != checked)
          .forEach((c) => {
            c.checked = checked;
          });

        this.updateTagsFromCheckboxes();
      },
      _keydown($event) {
        const { keyCode } = $event;

        // Esc => Cancel
        if (keyCode === 27) {
          const wasOpen = this.open;

          this.resetTags({ close: true });
          if (!wasOpen) this.$refs.field.reset();
        }
      },
      ...openCloseInternalMethods,
      setDropdownDimensions,
      toggle,
      addError,
      errorFormatter,
      resetValidation,
      validate,
      validates,
    },
    watch: {
      open(open) {
        this.setDropdownDimensions(this.$refs.field);

        if (!open) {
          this.$emit('input', this.valueFromTags());
        }
      },
    },
    created() {
      this.resetTags();
    },
    mounted,
    beforeDestroy,
  }
);
</script>

<style lang="scss">
@use 'sass:math';

// See ~ common/styles/field.scss
</style>
